import { fetchData } from "@/api/api";
import menuData from "@/data/menu-data";
import Link from "next/link";
import { useEffect, useState } from "react";

// {
//   link: "/shop",
//   title: "Shop",
//   hasDropdown: true,
//   megamenu: true,
//   dropdownItems: [
//     {
//       link: "/shop",
//       title: "Shop Pages",
//       dropdownMenu: [
//         { link: "/shop", title: "Standard Shop Page" },
//         { link: "/shop-right", title: "Shop Right Sidebar" },
//         { link: "/shop-4-col", title: "Shop 4 Column" },
//         { link: "/shop-3-col", title: "Shop 3 Column" },
//         { link: "/shop", title: "Shop Page" },
//         { link: "/shop", title: "Shop Page" },
//         { link: "/shop", title: "Shop Infinity" },
//       ],
//     },
//     {
//       link: "/shop",
//       title: "Products Pages",
//       dropdownMenu: [
//         { link: "/product-details", title: "Product Details" },
//         { link: "/product-details", title: "Product Page V2" },
//         { link: "/product-details", title: "Product Page V3" },
//         { link: "/product-details", title: "Product Page V4" },
//         { link: "/product-details", title: "Simple Product" },
//         { link: "/product-details", title: "Variable Product" },
//         { link: "/product-details", title: "External Product" },
//       ],
//     },
//     {
//       link: "/shop",
//       title: "Other Shop Pages",
//       dropdownMenu: [
//         { link: "/wishlist", title: "Wishlist" },
//         { link: "/compare", title: "Compare" },
//         { link: "/cart", title: "Shopping Cart" },
//         { link: "/checkout", title: "Checkout" },
//         { link: "/register", title: "Register" },
//         { link: "/login", title: "Login" },
//       ],
//     },
//   ],
// },

const NavManus = ({ setLoading }: any) => {
  const [menusData, setMenusData] = useState([...menuData]);
  const [categories, setCategories] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);

  const getCategories = async () => {
    const response = await fetchData({
      url: `/customer/categories`,
      cache: "force-cache",
    });

    setCategories(response?.data);

    const menuTemp: any = response?.data &&
      response?.data?.length > 0 && [
        {
          link: `/shop`,
          title: "Categories",
          hasDropdown: true,
          megamenu: false,
          dropdownItems: response?.data?.map((itm: any) => {
            return {
              link: `/shop?category=${itm?.id}&price=1000000`,
              title: itm?.name,
              dropdownMenu: itm?.subcategory?.map((sub: any) => {
                return {
                  link: `/shop?category=${sub?.categoryId}&price=1000000`,
                  title: sub?.name,
                };
              }),
            };
          }),
        },
      ];

    const response1 = await fetchData({
      url: `/customer/brands`,
      cache: "force-cache",
    });

    setBrands(response1?.data);

    const menuBrandTemp: any = response1?.data &&
      response1?.data?.length > 0 && [
        {
          link: `/shop`,
          title: "Brands",
          hasDropdown: true,
          megamenu: false,
          dropdownItems: response1?.data?.map((itm: any) => {
            return {
              link: `/shop?brand=${itm?.id}&price=1000000`,
              title: itm?.name,
              // dropdownMenu: itm?.subcategory?.map((sub: any) => {
              //   return {
              //     link: `/shop?category=${sub?.categoryId}`,
              //     title: sub?.name,
              //   };
              // }),
            };
          }),
        },
      ];

    setMenusData([...menusData, ...menuTemp, ...menuBrandTemp]);
  };

  useEffect(() => {
    getCategories();
    // getBrands();
  }, []);

  const clickMenu = async () => {
    setLoading(true);
  };

  return (
    <>
      {/* {loading && <Loader />} */}
      <nav>
        <ul>
          {menusData?.map((item, index) => {
            return (
              <li
                key={index}
                className={`${
                  item.hasDropdown && !item.megamenu
                    ? "active has-dropdown"
                    : item.megamenu && "mega-menu has-dropdown"
                }`}
              >
                <Link
                  href={`${item.link}`}
                  onClick={() => clickMenu()}
                >{`${item.title}`}</Link>

                {item?.hasDropdown && !item.megamenu && (
                  <ul className="submenu transition-3">
                    {item?.dropdownItems?.map((menu, index) => (
                      <li key={index}>
                        <Link href={`${menu.link}`} onClick={() => clickMenu()}>
                          {menu.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}

                {item.hasDropdown && item.megamenu && (
                  <ul
                    className="submenu transition-3"
                    style={{
                      // background: `url(${"/assets/img/bg/mega-menu-bg.jpg"})`,
                      // background: `url(${categories[0]?.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center right",
                      backgroundSize: "cover",
                    }}
                  >
                    {item.dropdownItems &&
                      item.dropdownItems.map((menu, index) => (
                        <li
                          key={index}
                          className={`${
                            menu.dropdownMenu ? "has-dropdown" : ""
                          }`}
                        >
                          <Link
                            href={`${menu.link}`}
                            onClick={() => clickMenu()}
                            // style={{ color: "#bc8246" }}
                          >
                            {menu.title}
                          </Link>
                          {/* <ul>
                          {menu?.dropdownMenu?.map((m, index) => (
                            <li key={index}>
                              <Link href={`${m.link}`}>-{m.title}</Link>
                            </li>
                          ))}
                        </ul> */}
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default NavManus;
